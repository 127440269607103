import React, { useState } from 'react';
import { Upload, Button, message, Table, Modal, Card } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import axios from 'axios';

const { Dragger } = Upload;

const App = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [visible, setVisible] = useState(false);

  const handleUpload = async () => {
    setVisible(true);
  };

  const handleOk = async () => {
    try {
      setUploading(true);
      await axios.post('https://mc-api-9rd7u.ondigitalocean.app/api/send/emails/cetpe', { emails });
      message.success('E-mails envoyés avec succès!');
    } catch (error) {
      message.error('Une erreur s\'est produite lors de l\'envoi des e-mails.');
    } finally {
      setUploading(false);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleFileRead = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      let emails = XLSX.utils.sheet_to_json(worksheet).map(row => row['email']); // Remplacez 'Email' par le nom de votre colonne d'e-mails
      emails = emails.filter(email => email); // Supprime les e-mails vides
      setEmails(emails);
    };
    reader.readAsArrayBuffer(file.originFileObj);
  };

  const columns = [
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
  ];

  return (
    <Card>
      <Dragger 
        fileList={fileList}
        onChange={({ fileList }) => {
          setFileList(fileList.slice(-1));
          handleFileRead(fileList[0]);
        }}
        beforeUpload={() => false} // Retourner false pour éviter le téléchargement automatique
        onClick={() => setEmails([])}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Cliquez ou faites glisser un fichier dans cette zone pour télécharger</p>
      </Dragger>
      <Button 
        type="primary" 
        onClick={handleUpload} 
        disabled={fileList.length === 0} 
        loading={uploading} 
        style={{ marginTop: 16 }}
      >
        {uploading ? 'Envoi...' : 'Commencer l\'envoi'}
      </Button>
      <Table dataSource={emails.map((email, key) => ({ key, email }))} columns={columns} />
      
      <Modal title="Confirmation" open={visible} onOk={handleOk} onCancel={handleCancel}>
        <p>Êtes-vous sûr de vouloir envoyer {emails.length} e-mails ?</p>
      </Modal>
    </Card>
  );
};

export default App;
